import React, { Fragment } from 'react'
import styled from 'styled-components'
import Range from 'components/Range'
const Label = styled.label({
  display: 'block',
})
const Knob = ({ onChange, ...props }) => {
  const { name, value } = props
  const label = props.label
    ? typeof props.label === 'function'
      ? props.label({ value, onChange })
      : props.label
    : name
  if (props.options) {
    return (
      <Label>
        {label}
        <select
          value={value}
          style={{ width: '100%' }}
          onChange={(e) => onChange(e.target.value)}
        >
          {props.options.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </select>
      </Label>
    )
  }
  if (value === true || value === false) {
    return (
      <Label>
        <input
          style={{ marginRight: 5 }}
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
        {label}
      </Label>
    )
  }
  return (
    <Label>
      {label}
      <Range {...props} onChange={(value) => onChange(value)} />
    </Label>
  )
}
const useKnobs = (initialValues) => {
  const [values, setValues] = React.useState(initialValues)
  return [
    Object.entries(values).reduce(
      (collection, [name, { value }]) => ({ ...collection, [name]: value }),
      {},
    ),
    <Fragment>
      {Object.keys(values).map((name) => (
        <Knob
          key={name}
          name={name}
          {...values[name]}
          onChange={(newValue) =>
            setValues({
              ...values,
              [name]: {
                ...values[name],
                value: newValue,
              },
            })
          }
        />
      ))}
    </Fragment>,
    Object.entries(values).reduce(
      (collection, [name, { value }]) => ({
        ...collection,
        [name]: (
          <Knob
            key={name}
            name={name}
            {...values[name]}
            onChange={(newValue) =>
              setValues({
                ...values,
                [name]: {
                  ...values[name],
                  value: newValue,
                },
              })
            }
          />
        ),
      }),
      {},
    ),
  ]
}
export default useKnobs
