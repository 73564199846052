import React from 'react'
import { Range, getTrackBackground } from 'react-range'
const CustomRange = ({ value, onChange, step, min, max }) => (
  <Range
    values={[value]}
    step={step}
    min={min}
    max={max}
    onChange={([value]) => onChange(value)}
    renderTrack={({ props, children }) => (
      <div
        onMouseDown={props.onMouseDown}
        onTouchStart={props.onTouchStart}
        style={{
          ...props.style,
          padding: '15px 0',
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          ref={props.ref}
          style={{
            height: '5px',
            width: '100%',
            borderRadius: '4px',
            background: getTrackBackground({
              values: [value],
              colors: ['#318CFC', '#d2dce9'],
              min,
              max,
            }),
            alignSelf: 'center',
          }}
        >
          {children}
        </div>
      </div>
    )}
    renderThumb={({ props, isDragged, value }) => (
      <div
        {...props}
        style={{
          ...props.style,
          height: 20,
          width: 20,
          borderRadius: 20,
          backgroundColor: '#FFF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)',
          fontSize: 12,
        }}
      />
    )}
  />
)
export default CustomRange
