import React from 'react'
import { useDrag } from 'react-use-gesture'
import useMeasure from 'react-use-measure'
import polyfill from '@juggle/resize-observer'

const BezierEditor = ({
  width = 500,
  height = 500,
  paddingHorizontal = 10,
  paddingVertical = 100,
  innerBackgroundColor = 'transparent',
  handleColor = '#f128a0',
  handleLineColor = '#f128a0',
  handleSize = 16,
  handleLineWidth = 2,
  curveColor = '#318cfc',
  curveLineWidth = 4,
  value: [p0, p1, p2, p3],
  onChange,
  children,
  scrollContainerRef,
}) => {
  const [containerBoundsRef, bounds] = useMeasure({ polyfill, scroll: true })
  const bottomLeft = [0, height]
  const topRight = [width, 0]
  const handleA = [width * p0, height * (1 - p1)]
  const handleB = [width * p2, height * (1 - p3)]

  const bind = useDrag(({ down, xy, args: [handle], first, last }) => {
    if (scrollContainerRef && scrollContainerRef.current && (first || last)) {
      scrollContainerRef.current.classList.toggle('scroll-lock', down)
    }
    const x = xy[0] - bounds.left - paddingHorizontal
    const y = Math.min(
      Math.max(
        -height - (paddingVertical - handleSize),
        xy[1] - bounds.top - paddingVertical - height,
      ),
      paddingVertical - handleSize,
    )
    const newX = Math.min(Math.max(0, x / width), 1)
    const newY = y / -height
    if (handle === 'start') {
      onChange([newX, newY, p2, p3])
    } else if (handle === 'end') {
      onChange([p0, p1, newX, newY])
    }
  })
  const widthWithPadding = width + paddingHorizontal * 2
  const heightWithPadding = height + paddingVertical * 2
  const frameWidth = 4
  return (
    <div
      style={{
        width: width + frameWidth,
        height: height + frameWidth,
        position: 'relative',
        zIndex: 2,
      }}
    >
      <svg
        width={widthWithPadding}
        height={heightWithPadding}
        ref={containerBoundsRef}
        style={{
          marginLeft: -paddingHorizontal,
          marginTop: -paddingVertical,
          pointerEvents: 'none',
        }}
      >
        <g
          transform={`
            translate(${paddingHorizontal} ${paddingVertical})
          `}
        >
          <rect
            x="0"
            y="0"
            width={width}
            height={height}
            fill={innerBackgroundColor}
          />
          {children && (
            <foreignObject x="0" y="0" width={width} height={height}>
              {React.cloneElement(children, {
                xmlns: 'http://www.w3.org/1999/xhtml',
              })}
            </foreignObject>
          )}
          <line
            stroke={handleLineColor}
            strokeWidth={handleLineWidth}
            strokeLinecap="round"
            x1={bottomLeft[0]}
            y1={bottomLeft[1]}
            x2={handleA[0]}
            y2={handleA[1]}
          />
          <line
            stroke={handleLineColor}
            strokeWidth={handleLineWidth}
            strokeLinecap="round"
            x1={topRight[0]}
            y1={topRight[1]}
            x2={handleB[0]}
            y2={handleB[1]}
          />
          <circle
            cx={bottomLeft[0]}
            cy={bottomLeft[1]}
            r={curveLineWidth}
            fill={curveColor}
          />
          <circle
            cx={topRight[0]}
            cy={topRight[1]}
            r={curveLineWidth}
            fill={curveColor}
          />
          <circle
            cx={handleA[0]}
            cy={handleA[1]}
            r={handleSize / 2}
            fill={handleColor}
          />
          <circle
            cx={handleB[0]}
            cy={handleB[1]}
            r={handleSize / 2}
            fill={handleColor}
          />
          <path
            stroke={curveColor}
            strokeWidth={curveLineWidth}
            strokeLinecap="round"
            fill="none"
            d={`
              M${bottomLeft}
              C${handleA} ${handleB} ${topRight}
            `}
          />
          <circle
            {...bind('start')}
            style={{ pointerEvents: 'auto' }}
            cx={handleA[0]}
            cy={handleA[1]}
            r={widthWithPadding * 0.04}
            fill="transparent"
          />
          <circle
            {...bind('end')}
            style={{ pointerEvents: 'auto' }}
            cx={handleB[0]}
            cy={handleB[1]}
            r={widthWithPadding * 0.04}
            fill="transparent"
          />
        </g>
      </svg>
    </div>
  )
}
export default BezierEditor
